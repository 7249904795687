var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{class:_vm.$style.card},[_c('LicensesHistoryFilters',{class:[_vm.$style.filters, 'pt-8'],on:{"filter_update":_vm.filter_update},scopedSlots:_vm._u([{key:"btn-outer",fn:function(){return [_c('v-btn',{class:[_vm.$style.btnOuter , 'mb-0'],attrs:{"text":""},on:{"click":function($event){_vm.cols_dialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tune")]),_c('span',[_vm._v("Настройка полей")])],1)]},proxy:true}])}),_vm._l((_vm.filtered_cols),function(header,idx){return _c('ResizeCols',{key:idx,attrs:{"header":header,"storage_name":_vm.cols_storage_name}})}),_c('Table',{class:_vm.$style.table,attrs:{"headers":_vm.filtered_cols,"items":_vm.frontol_licenses_history,"options":_vm.table_options,"custom-sort":_vm.prevent_sort,"loading":_vm.frontol_licenses_loading,"no-data-text":"Данные, удовлетворяющие выбранным параметрам, отсутствуют","hide-default-footer":""},on:{"update:options":function($event){_vm.table_options=$event},"click:row":_vm.row_click_handler},scopedSlots:_vm._u([{key:"item.license_key",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{class:{'d-flex align-center': !_vm.is_mobile}},[(item.license_key)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.license_key)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.license_key)+" ")])],1)])]}},{key:"item.nomenclature",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{class:{'d-flex align-center': !_vm.is_mobile}},[(item.nomenclature)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.nomenclature)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',{class:[_vm.$style.nomenclatureText, _vm.$style.textWrap]},[_vm._v(" "+_vm._s(item.nomenclature)+" ")])],1)])]}},{key:"item.activation_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(item.activation_at))+" ")])])]}},{key:"item.expired_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(item.expired_at))+" ")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('ChipStatus',{attrs:{"status":_vm.get_licenses_chip_status(item)}},[_vm._v(" "+_vm._s(item.status.name)+" ")])],1)]}},{key:"item.contacts",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('div',[_vm._v(_vm._s(item.client_contact))]),_c('div',{staticClass:"d-flex align-center",class:_vm.$style.is_mobile},[(item.client_phone)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.client_phone)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(_vm._s(item.client_phone))])],1),_c('div',{staticClass:"d-flex align-center",class:_vm.$style.is_mobile},[(item.client_email)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.client_email)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(_vm._s(item.client_email))])],1)])]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":function($event){return _vm.upd_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_pagination('limit', $event)}}})]},proxy:true}],null,true)})],2),_c('CustomColsDialog',{attrs:{"cols":_vm.cols,"cols_list":_vm.cols,"storage_name":_vm.cols_storage_name,"default_cols":_vm.cols_default},on:{"update:cols_list":function($event){_vm.cols=$event},"save_cols":function($event){_vm.cols_dialog = false},"cancel_dialog":function($event){_vm.cols_dialog = false},"close":function($event){_vm.cols_dialog = false},"default_limit":_vm.default_limit},model:{value:(_vm.cols_dialog),callback:function ($$v) {_vm.cols_dialog=$$v},expression:"cols_dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }